import Image from 'next/image'
import Link from 'next/link'

import type { ComponentAnalyticsClickProps } from '@cms/analytics/types'
import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import useNormalizeReference from '@cms/hooks/useNormalizeReference'
import { FALLBACK_IMAGES } from '@cms/utils/constants'
import {
  getDevicePixelRatioPriority,
  getImageWithFallback,
  getSmartCropBackgroundImageLoader,
} from '@cms/utils/utils'
import {
  CTARepeater,
  type CTARepeaterProps,
} from '@knauf-group/ct-designs/components/core/CTARepeater'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import { useTheme } from '@mui/material/styles'

import type { CTARepeaterWrapperProps } from './CTARepeater.types'

const CTARepeaterWrapper: React.FC<CTARepeaterWrapperProps> = (props) => {
  const theme = useTheme()
  const { devicePixelRatio } = useDevicePixelRatio()
  const { normalizeReference } = useNormalizeReference()

  const { fields: fieldsFromContentful } = props
  const { primaryReference, secondaryReference, image } = fieldsFromContentful || {}
  const analyticsProps: ComponentAnalyticsClickProps = {
    targetType: 'cta',
    cmsComponent: 'content_cta_repeater',
  }
  const primaryRef = primaryReference
    ? normalizeReference({
        reference: primaryReference,
        analyticsProps,
      })
    : null
  const secondaryRef = secondaryReference
    ? normalizeReference({
        reference: secondaryReference,
        analyticsProps,
      })
    : null
  // imageWithFallback should only be included if image is set in Contentful
  const imageWithFallback =
    image?.[0] && getImageWithFallback(image?.[0], FALLBACK_IMAGES.BACKGROUND)
  const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

  const componentProps: CTARepeaterProps = {
    ...fieldsFromContentful,
    primaryRef,
    secondaryRef,
    nextLinkComponent: Link,
    nextImageComponent: Image,
    // empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
    imageLoader: getSmartCropBackgroundImageLoader(
      CONTENTFUL_TYPES.CONTENT_CTA_REPEATER,
      theme.breakpoints.values,
      devicePixelRatioPriority,
    ),
    image: imageWithFallback,
    prefetch: false,
  }
  return <CTARepeater {...componentProps} />
}

export default CTARepeaterWrapper
